import { CompanySearchModelDto } from '@treasury/api/bo';
import { DomainEntity } from '../../../../shared';

const defaultCompany: CompanySearchModelDto = {
    id: 0,
    companyGuidUniqueId: '',
};

export class CompanySearchResult extends DomainEntity<CompanySearchModelDto> {
    constructor(dto: CompanySearchModelDto) {
        super(dto);
        this.cifNumber = dto.cifNumber;
        this.companyId = dto.companyId;
        this.name = dto.name;
        this.status = dto.status;
        this.taxNumber = dto.taxNumber;
    }

    public cifNumber?: string;

    public companyId?: string;

    public name?: string;

    public status?: string;

    public taxNumber?: string;

    public createDefault(): this {
        return new CompanySearchResult(defaultCompany) as this;
    }
}

import { UserSummaryModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyUser: UserSummaryModelDto = {
    id: 0,
    userId: '',
    status: '',
    isLocked: false,
    isAdmin: false,
    isSuperUser: false,
    isActive: false,
    isEditor: false,
    isMfaLocked: false,
    authenticationMethodId: 0,
};

export class CompanyUserSummary extends DomainEntity<UserSummaryModelDto> {
    constructor(dto = clone(nullCompanyUser)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyUserSummary(clone(nullCompanyUser)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get userId() {
        return this.dto.userId;
    }

    public get digitalId() {
        return this.dto.digitalId;
    }

    public get userName() {
        return this.dto.userName;
    }

    public get enrolledStatus() {
        return this.dto.status;
    }

    public get isActive() {
        return this.dto.isActive;
    }

    public set isActive(value: boolean) {
        this.dto.isActive = value;
    }
}

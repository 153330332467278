import { CompanyAccountSettingsModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyAccountSettings: CompanyAccountSettingsModelDto = {
    accountMaskTypeId: 1,
    allowUserToOverrideAccountMasking: false,
    useAccountNicknames: false,
};

export class CompanyAccountSettings extends DomainEntity<CompanyAccountSettingsModelDto> {
    constructor(dto = clone(nullCompanyAccountSettings)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyAccountSettings(clone(nullCompanyAccountSettings)) as this;
    }

    public get accountMaskingId() {
        return this.dto.accountMaskTypeId as 1 | 2 | 3;
    }

    public set accountMaskingId(value: 1 | 2 | 3) {
        this.dto.accountMaskTypeId = value;
    }

    public get allowUserToOverrideAccountMasking() {
        return this.dto.allowUserToOverrideAccountMasking;
    }

    public set allowUserToOverrideAccountMasking(value: boolean) {
        this.dto.allowUserToOverrideAccountMasking = value;
    }

    public get useAccountNicknames() {
        return this.dto.useAccountNicknames;
    }

    public set useAccountNicknames(value: boolean) {
        this.dto.useAccountNicknames = value;
    }
}

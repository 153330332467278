angular.module('backOffice').controller('ConfigureAchController', configureAchController);

configureAchController.$inject = [
    '$scope',
    'configurationService',
    'toaster',
    '$stateParams',
    '$modal',
    'modalService',
    'entitlementsService',
];

function configureAchController(
    $scope,
    configurationService,
    toaster,
    $stateParams,
    $modal,
    modalService,
    entitlementsService
) {
    $scope.approvalOptions = ['None', '1', '2', '3'];
    $scope.productFeature = {};
    $scope.productFeatureId = $stateParams.productFeatureId;

    $scope.stateTaxCodesFileName = null;
    $scope.stateTaxCodeFiles = null;

    $scope.cancel = cancel;
    $scope.save = save;
    $scope.getSecCodesString = getSecCodesString;
    $scope.manageSecCodes = manageSecCodes;
    $scope.addRoutingNumber = addRoutingNumber;
    $scope.showAddIcon = showAddIcon;
    $scope.deleteRoutingNumber = deleteRoutingNumber;
    $scope.daysInAdvanceOptions = [0, 1, 2];
    $scope.selectTab = selectTab;
    $scope.isTabSelected = isTabSelected;
    $scope.onStateTaxCodesUpload = onStateTaxCodesUpload;
    $scope.isSaveDisabled = isSaveDisabled;
    $scope.downloadStateTaxCodes = downloadStateTaxCodes;
    $scope.hasEntitlement = hasEntitlement;
    $scope.isChildSupportEnabled = isChildSupportEnabled;
    $scope.isAllowOnUsEnabled = isAllowOnUsEnabled;
    $scope.isInternationalACHEnabled = isInternationalACHEnabled;
    $scope.isAllowUnbalancedACHEnabled = isAllowUnbalancedACHEnabled;
    $scope.isAllowNotificationOfChangeEnabled = isAllowNotificationOfChangeEnabled;
    $scope.isAllowReturnReviewEnabled = isAllowReturnReviewEnabled;
    $scope.checkEmptyAchLimitField = checkEmptyAchLimitField;
    $scope.hasInternationalTemplateEntitlement = hasInternationalTemplateEntitlement;

    init();

    function init() {
        $scope.productFeature = {
            paymentApprovalCount: 'None',
            batchApprovalCount: 'None',
            paymentAuthenticationType: 'None',
            batchAuthenticationType: 'None',
        };

        loadProductFeature();

        selectTab('ACH Settings');
    }

    function selectTab(tabName) {
        $scope.selectedTab = tabName;
    }

    function isTabSelected(tabName) {
        return tabName === $scope.selectedTab;
    }

    function save() {
        configurationService
            .saveProductFeature($scope.productFeatureId, $scope.productFeature)
            .then(response => {
                toaster.save('ACH Configuration');
                $scope.form.$setPristine();
            });
    }

    function cancel() {
        loadProductFeature();

        $scope.stateTaxCodeFiles = null;
    }

    function hasInternationalTemplateEntitlement() {
        return hasEntitlement('Feature.Ach.InternationalPaymentTemplates');
    }

    function isChildSupportEnabled() {
        return hasEntitlement('Feature.ACH.AllowChildSupportPayments');
    }

    function isAllowOnUsEnabled() {
        return hasEntitlement('Feature.ACH.AllowOnUsAccessManagement');
    }

    function isInternationalACHEnabled() {
        return hasEntitlement('Feature.ACH.AllowInternationalPayments');
    }

    function isAllowUnbalancedACHEnabled() {
        return hasEntitlement('Feature.ACH.AllowUnbalancedPayments');
    }

    function isAllowNotificationOfChangeEnabled() {
        return (
            hasEntitlement('Feature.Ach.NotificationOfChange') ||
            hasEntitlement('Feature.ACH.NotificationOfChange.Nacha')
        );
    }

    function isAllowReturnReviewEnabled() {
        return (
            hasEntitlement('Feature.Ach.ReturnReview') || hasEntitlement('Feature.ACH.Return.Nacha')
        );
    }

    function hasEntitlement(entitlement) {
        return entitlementsService.hasEntitlement(entitlement);
    }

    function getSecCodesString() {
        if ($scope.productFeature.secCodes) {
            return $scope.productFeature.secCodes
                .map(elem =>
                    elem.code === 'IAT' && !$scope.productFeature.allowInternationalAchPayments
                        ? ''
                        : `${elem.code}-${elem.description}`
                )
                .join(', ');
        }
    }

    function checkEmptyAchLimitField() {
        if (!$scope.productFeature.sameDayAchLimit) {
            $scope.productFeature.sameDayAchLimit = 0;
        }
    }

    function deleteRoutingNumber(index) {
        $scope.productFeature.onUsRoutingNumbers.splice(index, 1);
        $scope.form.$dirty = true;
    }

    function showAddIcon(index) {
        if ($scope.productFeature.onUsRoutingNumbers.length > 0) {
            return index === $scope.productFeature.onUsRoutingNumbers.length;
        }
    }

    function addRoutingNumber() {
        const obj = {
            abaNumber: '',
            fiName: '',
        };
        $scope.productFeature.onUsRoutingNumbers.push(obj);
        $scope.form.$dirty = true;
    }

    function manageSecCodes() {
        const modalInstance = $modal.open({
            template: require('../views/manageSecCodesModalTemplate.html'),
            size: 'md',
            controller: 'ManageSecCodesController',
            backdrop: 'static',
            resolve: {
                selectedSecCodes() {
                    return $scope.productFeature.secCodes;
                },
                productFeatureId() {
                    return null;
                },
                allowInternationalAchPayments() {
                    return $scope.productFeature.allowInternationalAchPayments;
                },
            },
        });
        modalInstance.result.then(response => {
            if ($scope.productFeature.secCodes !== response) {
                $scope.form.$setDirty(true);
            }
            $scope.productFeature.secCodes = response;
        });
    }

    function loadProductFeature() {
        configurationService.getProductFeatureDetails($scope.productFeatureId).then(response => {
            $scope.productFeature = response;
            $scope.productFeature.has = function (feature) {
                return (
                    $scope.productFeature.hasOwnProperty(feature) &&
                    typeof $scope.productFeature[feature] === 'boolean'
                );
            };
            $scope.form.$setPristine();
        });

        configurationService.getAchStateTaxCodeFileMetadata().then(metadata => {
            $scope.stateTaxCodesFileName = metadata.stateTaxCodesFileName;
        });
    }

    function isSaveDisabled() {
        return $scope.form.$pristine || $scope.form.$invalid;
    }

    function onStateTaxCodesUpload(files) {
        const file = files[0];

        configurationService.uploadAchStateTaxCodes(file).then(response => {
            if (response.success) {
                $scope.stateTaxCodesFileName = file.name;
                toaster.save('File');
            } else {
                modalService.showModal(
                    {},
                    {
                        alertType: 'Error',
                        isAlert: true,
                        summaryText: response.fileErrors.summaryMessage,
                    }
                );
            }
        });
    }

    function downloadStateTaxCodes() {
        configurationService.downloadAchStateTaxCodes();
    }
}

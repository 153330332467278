import { Injectable, InjectProperty } from '@jack-henry/frontend-utils/di';
import {
    ApplicationNameTypeDto,
    CompaniesClient,
    UserActivityTypeDto,
    UsersClient,
} from '@treasury/api/bo';
import Fuse from 'fuse.js';
import { uniqWith } from 'lodash';
import { Feature, FeatureFlagService } from '../../../services/feature-flags';
import { mapUserTimeAccess } from '../../mappings/time-access/map-user-time-access-response';
import UserRequests from '../../requests/users/user-requests.js';
import { User } from './user.entity';

export { UserActivityTypeDto as UserActivityTypes } from '@treasury/api/bo';

type UserActivityTypes = UserActivityTypeDto;
export enum UserSearchField {
    UserName = 'UserName',
    DigitalId = 'DigitalId',
    UserAlias = 'UserAlias',
}

@Injectable()
export class UsersService {
    constructor(
        private readonly companiesClient: CompaniesClient,
        private usersClient: UsersClient
        // eslint-disable-next-line no-empty-function
    ) {}

    @InjectProperty({
        token: FeatureFlagService,
        async: true,
    })
    private declare ffServicePromise: Promise<FeatureFlagService>;

    public async searchFiUsers(
        searchText: string,
        searchType: UserSearchField = UserSearchField.UserName
    ) {
        const resp = await this.usersClient.usersSearchAllChannelUser({ searchText, searchType });
        return resp.data.map(u => new User(u));
    }

    public async fuzzySearchFiUsers(searchText: string) {
        const response = await Promise.all([
            this.searchFiUsers(searchText, UserSearchField.UserName),
            this.searchFiUsers(searchText, UserSearchField.DigitalId),
            this.searchFiUsers(searchText, UserSearchField.UserAlias),
        ]);
        const dedupedUsers = uniqWith(response.flat(), (a, b) => a.userId === b.userId);
        const fuse = new Fuse(dedupedUsers, {
            keys: ['userName', 'digitalId', 'userAlias'],
        });
        return fuse.search(searchText).map((result: any) => result.item);
    }

    static changeUserStatus(userPayload: any, newStatus: string) {
        return UserRequests.changeUserStatus(userPayload, newStatus);
    }

    static deleteUser(userId: number) {
        return UserRequests.deleteUser(userId);
    }

    static forgotPassword(userPayload: any) {
        return UserRequests.forgotPassword(userPayload);
    }

    static getActiveUsers() {
        return UserRequests.getActiveUsers();
    }

    static async getCurrentUserTimeAccess() {
        const response = await UserRequests.getCurrentUserTimeAccess();
        return mapUserTimeAccess(response);
    }

    static getNewUserTimeAccess() {
        return UserRequests.getNewUserTimeAccess();
    }

    static getUser(userId: number) {
        return UserRequests.getUser(userId);
    }

    static getUserLoginTokenDetail(userId: number) {
        return UserRequests.getUserLoginTokenDetail(userId);
    }

    static getUsers() {
        return UserRequests.getUsers();
    }

    static getAllUsers() {
        return UserRequests.getAllUsers();
    }

    static async getUsersByCompanyIds(companyIds: string) {
        const users = await UserRequests.getUsersByCompanyIds(companyIds);
        const index = users.findIndex((user: { userId: string }) => user.userId === 'System');
        const systemUser = users.splice(index, 1)[0];
        type User = { userName: string };
        users.sort((a: User, b: User) => a.userName.localeCompare(b.userName));
        users.unshift(systemUser);
        return users;
    }

    static manualResetPassword(userId: string, passwordPayload: any) {
        return UserRequests.manualResetPassword(userId, passwordPayload);
    }

    static resetPassword(userId: number) {
        return UserRequests.resetPassword(userId);
    }

    static resetSecureToken(userId: number) {
        return UserRequests.resetSecureToken(userId);
    }

    static saveUser(userPayload: any) {
        return UserRequests.saveUser(userPayload);
    }

    static unlock(userId: number) {
        return UserRequests.unlock(userId);
    }

    static unlockSecureToken(userId: number, recollectToken: string) {
        return UserRequests.unlockSecureToken(userId, recollectToken);
    }

    static updateUser(userPayload: any) {
        return UserRequests.updateUser(userPayload);
    }

    async saveUserActivity(
        userActivityType: UserActivityTypes,
        activityMessage: string,
        relatedId = undefined,
        activityDate: Date = new Date()
    ) {
        const response = await this.usersClient.usersSaveUserActivity({
            userActivityType: userActivityType as UserActivityTypeDto,
            activityMessage,
            relatedId,
            activityDate: activityDate.toISOString(),
            applicationNameType: ApplicationNameTypeDto.TreasuryBackOffice,
        });
        return response.data;
    }

    public async getCompanyUsers(companyId: number, enrolledAndActive: boolean) {
        const uisEnabled = await (await this.ffServicePromise).isEnabled(Feature.UisEnabled);
        const usersEndpoint = uisEnabled
            ? this.companiesClient.companiesGetUsers(companyId, enrolledAndActive, {})
            : this.companiesClient.companiesUsersAll(companyId, enrolledAndActive, {});

        const response = await usersEndpoint;
        return response.data;
    }
}

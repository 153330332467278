import { UserInformationModelDto, UserSummaryModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyUser: UserInformationModelDto = {
    userId: 0,
    name: '',
    loginId: '',
    department: '',
    email: '',
    phone: '',
    extension: '',
    fax: '',
    isMfaBlocked: false,
    isSuperUser: false,
    isAccountMaskingConfigurable: false,
    isMethodOfVerificationConfigurable: false,
    isAdmin: false,
    copyUserId: 0,
    isEnrolled: false,
    isActive: false,
    hasLoggedIn: false,
    success: false,
    pendingEditOfUserUniqueId: '',
};

export class CompanyUserInformation extends DomainEntity<UserInformationModelDto> {
    constructor(dto = clone(nullCompanyUser)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyUserInformation(clone(nullCompanyUser)) as this;
    }

    public get loginId() {
        return this.dto.loginId;
    }

    public set loginId(value: string) {
        this.dto.loginId = value;
    }

    public get name() {
        return this.dto.name;
    }

    public set name(value: string) {
        this.dto.name = value;
    }

    public get rsaId() {
        return this.dto.mfaId;
    }

    public get isActive() {
        return this.dto.isActive;
    }

    public get isSuperUser() {
        return this.dto.isSuperUser;
    }

    public set isSuperUser(value: boolean) {
        this.dto.isSuperUser = value;
    }

    public get isAdmin() {
        return this.dto.isAdmin;
    }

    public set isAdmin(value: boolean) {
        this.dto.isAdmin = value;
    }

    public get email() {
        return this.dto.email;
    }

    public set email(value: string) {
        this.dto.email = value;
    }

    public get phone() {
        return this.dto.phone;
    }

    public set phone(value: string) {
        this.dto.phone = value;
    }

    public get fax() {
        return this.dto.fax;
    }

    public set fax(value: string) {
        this.dto.fax = value;
    }

    public get department() {
        return this.dto.department;
    }

    public set department(value: string) {
        this.dto.department = value;
    }
}

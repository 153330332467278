import { AccountPermissionDto, FromAccountPermissionDto } from '@treasury/api/bo/overrides';
import { DomainEntity } from '../../../../shared';

export class AccountPermission extends DomainEntity<AccountPermissionDto> {
    public createDefault(): this {
        throw new Error('not implemented');
    }

    public get toAccountType() {
        return this.dto.toAccountType;
    }

    public set toAccountType(type: string) {
        this.dto.toAccountType = type;
    }

    public get fromAccountTypes() {
        return this.dto.fromAccountTypes;
    }

    public set fromAccountTypes(types: FromAccountPermissionDto[]) {
        this.dto.fromAccountTypes = types;
    }

    public get id() {
        return this.dto.id;
    }

    public set id(value: number) {
        this.dto.id = value;
    }
}

import { clone } from '@jack-henry/frontend-utils/functions';
import { AccountPermissionDto, TransferProductFeatureModelDto } from '@treasury/api/bo/overrides';
import { AccountPermission } from './account-permission.entity';
import { CompanyProductFeature } from './company-product-feature.entity';

enum AccountType {
    Checking = 1,
    Savings,
    Loans,
    Mortgage, // unused, preserved here for parity with the API backend
    Club,
    'Time Deposit',
}

export class TransferProductFeature extends CompanyProductFeature {
    constructor(public dto: TransferProductFeatureModelDto) {
        super(dto);
        this.accountPermissions = dto.accountPermissions.map(
            permission => new AccountPermission(permission)
        );
    }

    // Pre-initialized to an array of 5 empty arrays so that we can use the updateRelationship method to set the incoming data
    private _transferRelationships: boolean[][] = [[], [], [], [], []];

    public get accountTypes() {
        return ['Checking', 'Savings', 'Club', 'Time Deposit', 'Loans'];
    }

    public getAccountType(index: number) {
        return this.accountTypes[index];
    }

    private getAccountTypeId(type: string) {
        return AccountType[type as keyof typeof AccountType];
    }

    public updateRelationship(fromIndex: number, toIndex: number, checked: boolean) {
        this._transferRelationships[fromIndex][toIndex] = checked;
        this.dto.accountPermissions = this.accountPermissions.map(permission => permission.toDto());
    }

    public isTransferAllowed(fromAccountIndex: number, toAccountIndex: number) {
        return this._transferRelationships[fromAccountIndex][toAccountIndex];
    }

    public get accountPermissions() {
        // The order the API expects is pivoted from our internal representation, hence the need to pivot it back
        const relationships = [];
        for (const toAccountIndex in this.accountTypes) {
            const toAccountType = this.accountTypes[toAccountIndex];
            const account: AccountPermissionDto = {
                id: this.getAccountTypeId(toAccountType),
                toAccountType,
                fromAccountTypes: [],
            };
            for (const fromAccountIndex in this.accountTypes) {
                const fromAccountType = this.accountTypes[fromAccountIndex];
                const isAllowed = this._transferRelationships[fromAccountIndex][toAccountIndex];
                account.fromAccountTypes!.push({
                    id: this.getAccountTypeId(fromAccountType),
                    fromAccountType,
                    isAllowed,
                });
            }
            relationships.push(new AccountPermission(account));
        }
        return relationships;
    }

    public set accountPermissions(permissions: AccountPermission[]) {
        for (const toAccountIndex in permissions) {
            const toAccount = permissions[toAccountIndex];
            for (const fromAccountIndex in toAccount.fromAccountTypes ?? []) {
                const fromAccount = toAccount.fromAccountTypes[fromAccountIndex];
                this.updateRelationship(
                    Number(fromAccountIndex),
                    Number(toAccountIndex),
                    fromAccount.isAllowed
                );
            }
        }
    }

    public get transferRelationships() {
        return clone(this._transferRelationships);
    }

    public get dailyLimit() {
        return this.dto.dailyLimit;
    }

    public set dailyLimit(value: number | undefined) {
        this.dto.dailyLimit = value;
    }

    public get approval() {
        return this.dto.approval;
    }

    public set approval(value: 'None' | number | undefined) {
        this.dto.approval = value;
    }

    public get templateApproval() {
        return this.dto.templateApproval;
    }

    public set templateApproval(value: 'None' | number | undefined) {
        this.dto.templateApproval = value;
    }

    public get allowLoanPaymentPastDue() {
        return this.dto.allowLoanPaymentPastDue ?? false;
    }

    public set allowLoanPaymentPastDue(value: boolean) {
        this.dto.allowLoanPaymentPastDue = value;
    }
}

import { Injectable } from '@jack-henry/frontend-utils/di';
import { CompanyuserClient as CompanyUserClient } from '@treasury/api/bo';
import { LocalStorageService } from '@treasury/utils';
import { CompanyUserSummary } from './entities';
import { CompanyUserInformation } from './entities/company-user-information.entity';
import { RecentlyViewedUser, RecentlyViewedUsers } from './types/recently-viewed-users';

@Injectable()
export class CompanyUserService {
    constructor(
        private readonly companyUserClient: CompanyUserClient,
        private readonly localStorageService: LocalStorageService
    ) {}

    sendEnrollmentEmail(companyId: number, userId: number) {
        this.companyUserClient.companyUserGenerateEnrollmentEmail(companyId, userId);
    }

    public async getCompanyUser(companyId: number, userId: number) {
        const { user } = (
            await this.companyUserClient.companyUserGetUserInformation(companyId, userId)
        ).data;
        return new CompanyUserInformation(user);
    }

    async setActive(
        companyId: number,
        userId: number,
        isActive: boolean,
        comments: string | null = null
    ) {
        const user = (
            await this.companyUserClient.companyUserSetActive(companyId, userId, {
                isActive,
                comments,
            })
        ).data;
        return new CompanyUserSummary(user);
    }

    /**
     * Saves a user to the recently viewed users list for a given company.
     *
     * This method updates the list of recently viewed users for a specified company by adding
     * the provided user to the beginning of the list. If the user already exists in the list,
     * it is moved to the beginning. If the list exceeds 5 users, the oldest user is removed.
     *
     * @param companyId - The ID of the company.
     * @param user - The user to be added to the recently viewed users list.
     */
    public saveUserToRecentlyViewedUser(companyId: number, user: RecentlyViewedUser) {
        const recentlyViewed = this.getRecentlyViewedCompanyUsers(companyId).filter(
            u => u.id !== user.id
        );
        if (!recentlyViewed || recentlyViewed.length < 1) {
            this.setRecentlyViewedCompanyUsers(companyId, [user]);
            return;
        }

        if (recentlyViewed) {
            if (recentlyViewed.length > 5) {
                recentlyViewed.pop();
            }
            recentlyViewed.unshift(user);
            this.setRecentlyViewedCompanyUsers(companyId, recentlyViewed);
        }
    }

    private setRecentlyViewedCompanyUsers(companyId: number, users: RecentlyViewedUser[]) {
        this.localStorageService.set('recentlyViewedUsers', {
            ...this.localStorageService.get<RecentlyViewedUsers>('recentlyViewedUsers'),
            [companyId]: users,
        });
    }

    /**
     * Retrieves the list of recently viewed users for a given company.
     *
     * @param {number} companyId - The ID of the company.
     * @returns {RecentlyViewedUsers[]} An array of recently viewed users for the specified company.
     */
    public getRecentlyViewedCompanyUsers(companyId: number) {
        const recentlyViewed =
            this.localStorageService.get<RecentlyViewedUsers>('recentlyViewedUsers');

        return recentlyViewed ? recentlyViewed[companyId] : [];
    }

    public async saveCompanyUser(companyId: number, userId: number, user: CompanyUserInformation) {
        return await this.companyUserClient.companyUserSaveUserInformation(
            companyId,
            userId,
            user.toDto()
        );
    }
}

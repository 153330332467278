import { AssociatedCompanyModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullAssociatedCompany: AssociatedCompanyModelDto = {
    holdingCompanyId: 0,
    id: 0,
};

export class AssociatedCompany extends DomainEntity<AssociatedCompanyModelDto> {
    constructor(dto = clone(nullAssociatedCompany)) {
        super(dto);
    }

    public createDefault(): this {
        return new AssociatedCompany(clone(nullAssociatedCompany)) as this;
    }

    public get companyName() {
        return this.dto.name;
    }

    public get taxNumber() {
        return this.dto.taxNumber;
    }

    public get cifNumber() {
        return this.dto.cifNumber;
    }

    public get address() {
        return this.dto.address;
    }
}
